<template>
	<section class="layer" style="display:block">
		<div class="layer__dim"></div>
        <!-- [D] 가로/세로 가운데 정렬 레이어 일 경우 layer__wrap--centered 클래스 추가 -->
		<div class="layer__wrap layer__wrap--centered">
			<div class="layer-message">
                
                <div class="layer-message__top" style="padding: 20px 10px 20px;">
                 
                    <h3 class="layer-message__title">
	                    지도
                    </h3>
			    <!-- 숙소 맵 -->
			    <article id="detail-map2" class="detail__map2">
			    	<!-- [D] 샘플 지도 영역 -->
			    </article>
                </div> 
                <div class="layer-message__btns">
                    <button type="button" class="btn skyblue" @click="handleClose">
                        {{t('10143')}}
                    </button>
                </div>
			</div>
		</div>
	</section>
</template>

<script>

import { computed,onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRoute,onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 

export default {
    props: ["handleClose", "type", "handleClick"],
    setup(props) {
        
		let map = null;
		const route = useRoute();
		const store = useStore();
        
		const hotelNo = route.params.id;
		const hotel = computed(() => (store.state.mrm02.hotel[hotelNo] || []));
        const { t }= useI18n() 

        onMounted(() => {
			store.dispatch("mrm02/fetchMrm0201", { hotelNo });
			store.dispatch("mrm02/fetchMrm0203", { hotelNo });
			store.dispatch("mrm02/fetchMrm0204", { hotelNo });
			store.dispatch("mrm02/fetchMrm0205", { hotelNo });
			store.watch(() => store.state.mrm02.hotel[hotelNo], (hotel) => {
                //console.log(hotel[0].LOC_LATITUDE, hotel[0].LOC_LONGITUDE)
				if (hotel && hotel.length > 0){
                    //console.log("!23123")
					const container = document.getElementById('detail-map2'); //지도를 담을 영역의 DOM 레퍼런스
					const hotelLocation = new window.kakao.maps.LatLng(hotel[0].LOC_LATITUDE, hotel[0].LOC_LONGITUDE);
					const options = { //지도를 생성할 때 필요한 기본 옵션
						center: hotelLocation, //지도의 중심좌표.
						level: 4 //지도의 레벨(확대, 축소 정도)
					};
					map = new window.kakao.maps.Map(container, options);
                    //console.log(map)
					// 마커를 생성합니다
					new window.kakao.maps.Marker({
						map: map,
						position: hotelLocation
					});
				}
			}, {immediate: true});
		});
        onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})
        return {
             t,  //번역필수 모듈
		  i18n, 
          hotel
        }
    },
}
</script>